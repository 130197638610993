export function setupAccordion (el: HTMLDetailsElement) {
  // The <summary> element toggles the content inside <detail>
  const summary = el.querySelector<HTMLElement>('summary')!

  // The content element that will be revealed
  const content = el.querySelector<HTMLElement>('.content')!

  // The animation object, so we can cancel it in flight
  let animation: Animation | null = null

  type AccordionState = 'open' | 'closed' | 'collapsing' | 'expanding'
  let state = $ref<AccordionState>(el.open ? 'open' : 'closed')

  // Toggle a class to indicate that it's collapsing
  watch(() => state, () => el.classList.toggle('collapsing', state === 'collapsing'))

  // Detect user clicks on the summary element
  summary.addEventListener('click', (event) => {
    // Stop default behaviour from the browser, which would show/hide content.
    event.preventDefault()

    // Add an overflow on the <details> to avoid content overflowing
    el.style.overflow = 'hidden'

    // Check if the element is being closed or is already closed
    if (state === 'collapsing' || !el.open) {
      open()
    // Check if the element is being openned or is already open
    }
    else if (state === 'expanding' || el.open) {
      animate('collapsing')
    }
  })

  function animate (newState: AccordionState) {
    state = newState

    // Store the current height of the element
    const startHeight = `${el.offsetHeight}px`

    // Calculate the height of the summary
    const endHeight = state === 'expanding'
      ? `${summary.offsetHeight + content.offsetHeight}px`
      : `${summary.offsetHeight}px`

    // If there is already an animation running, cancel it.
    animation?.cancel()

    // Start a WAAPI animation, specifying the keyframes.
    animation = el.animate({ height: [startHeight, endHeight] }, {
      duration: 250,
      easing: 'ease-out',
    })
    animation.onfinish = onAnimationFinish
  }

  function open () {
    // Apply a fixed height on the element
    el.style.height = `${el.offsetHeight}px`

    // Force the [open] attribute on the details element
    el.open = true

    // Wait for the next frame to call the expand function
    requestAnimationFrame(() => animate('expanding'))
  }

  function onAnimationFinish () {
    el.open = state === 'expanding'
    state = el.open ? 'open' : 'closed'

    // Clear the stored animation
    animation = null

    // Remove the overflow hidden and the fixed height
    el.style.height = el.style.overflow = ''
  }
}
