import '@consumer/styles/consumer_gift_cards.scss'

export { setupAccordion } from '@consumer/gift_cards/accordion'

// Reveals a hidden element, animating its height.
export function reveal (el: HTMLElement) {
  el.style.overflow = 'hidden'
  el.classList.remove('hidden')

  const endHeight = `${el.offsetHeight}px`
  const startHeight = el.style.height = '0px'

  requestAnimationFrame(() => {
    const animation = el.animate({
      height: [startHeight, endHeight],
    }, { duration: 250, easing: 'ease-out' })
    animation.onfinish = () => {
      el.style.height = el.style.overflow = ''
    }
  })
}

// Sets a CSS variable in each gift card to ensure all elements within it are
// sized proportionally, by leveraging em units.
export function resizeImageWrapper (placeDefaultImageUrl: string) {
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries)
      (entry.target as HTMLElement)?.style.setProperty('--giftly-font-size', `${entry.contentRect.width / 360}px`)
  })

  document.querySelectorAll('.giftly-image-wrapper').forEach(el => resizeObserver.observe(el))

  document.querySelectorAll<HTMLImageElement>('.giftly-v3--business-image').forEach((img) => {
    img.addEventListener('error', () => { img.src = placeDefaultImageUrl })
    if (img.complete) img.src = img.src // eslint-disable-line no-self-assign
  })
}

// Scrolls the user to the results section when selecting a different page of results.
export function scrollCardsIntoView () {
  const urlParams = new URLSearchParams(location.search)
  const page = parseInt(urlParams.get('page') || '1')
  if (page > 1)
    document.querySelector('section:has(.gift-cards__pagination)')?.scrollIntoView({ behavior: 'smooth' })
}

// Collapse the section, and allow the user to manually expand it.
export function collapseLongSections () {
  document.querySelectorAll<HTMLElement>('[data-collapsible=\'toggle\']').forEach((el) => {
    const container = el.closest<HTMLElement>('[data-collapsible=\'container\']')
    if (!container) return console.warn('Missing collapsible container', el)

    const fullHeight = container.offsetHeight

    const maxHeight = Number(container.dataset.collapsibleHeight)
    if (!isNaN(maxHeight)) {
      if (fullHeight < maxHeight) return
      container.style.height = `${maxHeight}px`
    }
    else {
      const limitHeight = 500
      if (fullHeight < limitHeight) return
      container.style.height = `${Math.max(fullHeight / 3, limitHeight)}px`
    }

    el.classList.remove('hidden')
    el.addEventListener('click', () => {
      container.addEventListener('transitionend', () => container.style.height = 'auto')
      container.style.transition = 'height 0.3s ease'
      container.style.height = `${container.scrollHeight}px`
      el.classList.add('hidden')
    })
  })
}

export function setupDragToScroll (el: HTMLElement) {
  let isDown = false
  let startX: number
  let scrollLeft: number
  const ratio = 1 // Increase to scroll faster.

  el.querySelector('[name="Scroll Left"]')?.addEventListener('click', () => {
    el.scrollBy({ left: -1000, behavior: 'smooth' })
  })

  el.querySelector('[name="Scroll Right"]')?.addEventListener('click', () => {
    el.scrollBy({ left: 1000, behavior: 'smooth' })
  })

  el.addEventListener('mousedown', (e) => {
    isDown = true
    el.classList.add('active')
    startX = e.pageX - el.offsetLeft
    scrollLeft = el.scrollLeft
  })

  el.addEventListener('mouseleave', () => {
    isDown = false
    el.classList.remove('active')
  })

  el.addEventListener('mouseup', () => {
    isDown = false
    el.classList.remove('active')
  })

  el.addEventListener('mousemove', (e) => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - el.offsetLeft
    const walk = (x - startX) * ratio
    el.scrollLeft = scrollLeft - walk
  })
}
